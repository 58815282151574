.search-container {
  position: relative;
  width: 100%;
  .dropdown {
    display: flex;
    width: 100%;
    background-color: var(--grey_1);
    position: relative;
    cursor: pointer;
    flex-direction: column;
    @media screen and (max-width: 480px) {
      width: 100%;
    }
    a {
      color: #000;
      padding: 14px;
      text-decoration: none;
      margin-inline: 20px;
      background-color: #e9eef666;
      border-bottom: 0.5px solid;

      &:hover {
        background-color: var(--grey_2);
      }
    }
    .input-container {
      position: relative;
      width: 100%;
    }
    mat-icon {
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
      color: grey;
    }
    .input {
      border-radius: 5px;
      border: 1px solid #ccc;
      width: 100%;
      padding-inline: 20px;
      height: 50px;
      font-size: 17px;
      &:hover {
        cursor: pointer;
      }
      &::placeholder {
        color: black;
        font-style: italic;
        opacity: 0.7;
        font-weight: 300;
        @media screen and (max-width: 480px) {
          font-size: 13px;
        }
      }
    }

    .has-results {
      overflow: auto;
      max-height: 600px;
      z-index: 5;
      border: 1px solid var(--grey_3);
      position: absolute;
      width: 100%;
      border-radius: 0 0 10px 10px;
      height: 100vh;
      top: 40px;
      background-color: var(--grey_1);
      .loader {
        margin: 13px;
      }
      .result {
        background-color: white;
        margin: 13px;
        box-shadow: 0 1px 4px #0003;
        border-radius: 4px;
        .header {
          width: 100%;
          h3 {
            font-size: 18px;
          }
          .content_preview {
            padding: 10px;
            .header_line {
              display: flex;
              justify-content: space-between;
              .title {
                padding-bottom: 5px;
              }
              .type {
                padding-right: 5px;
                align-content: center;
              }
            }
            .img_and_text_container {
              display: flex;
              column-gap: 10px;
              place-content: space-between;
              .text_container {
                width: 80%;
              }
              .image_container {
                width: 80px;
                height: 80px;
                .img {
                  border-radius: 10px;
                  object-fit: cover;
                  height: 100%;
                }
              }
            }
          }
          .text {
            padding-top: 10px;
            font-size: 14px;
            p {
              padding-top: 10px;
              font-size: 14px;
            }
          }
        }
        &:hover {
          cursor: pointer;
        }
      }

    }
  }
}
